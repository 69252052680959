import { useQuery } from '@tanstack/react-query';
import { format, parseISO } from 'date-fns';
import { useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { PageProps } from '../@types';
import { getSetupIPAOptions } from '../pages/Administrador/Produtos/Components/SetupIPA/SetupIPA.services';
import { getSetupIPAConfiguracoesBasicas } from '../pages/Administrador/Produtos/Components/SetupIPAConfiguracoesBasicas/services';
import { GET_SETUP_IPA_PRECO_ATACADO } from '../pages/Administrador/Produtos/Components/SetupIPAPrecoAtacado/services';
import { SET_SEGMENTOS } from '../reducers/gerenciadorPrecos/segmentos';
import { SET_IPA_SETUP_COMPETITORS_DAYS } from '../reducers/reducerIPA/basicConfig/competitorsDays';
import { SET_DADOS_INFOPANEL } from '../reducers/reducerIPA/dadosInfopanel';
import { SET_LAST_INTEGRATION_DATE } from '../reducers/reducerIPA/lastIntegrationDate';
import { SET_SETUP_WHOLESALE } from '../reducers/reducerIPA/wholesale';
import { getCattegories, getLastUpdate } from '../services/ProdutoService_ts';

type IPAProviderProps = PageProps;

const STALE_TIME = 1000 * 60 * 60; // 60 minutes

const IPAProvider = ({ children, usuario }: IPAProviderProps) => {
    const IS_ENABLED_QUERY = useMemo(() => {
        return !!usuario?.servicos?.find((servico) => servico.name === 'IPA');
    }, [usuario]);

    const dispatch = useDispatch();

    useQuery({
        queryKey: ['ipa/setup-form/competitor-days'],
        enabled: IS_ENABLED_QUERY,
        staleTime: STALE_TIME,
        queryFn: getSetupIPAConfiguracoesBasicas,
        onSuccess: (data) => dispatch(SET_IPA_SETUP_COMPETITORS_DAYS(data)),
    });

    useQuery({
        queryKey: ['setup-ipa', 'dados-infopanel'],
        queryFn: getSetupIPAOptions,
        enabled: IS_ENABLED_QUERY,
        staleTime: STALE_TIME,
        onSuccess: (data) => dispatch(SET_DADOS_INFOPANEL(data)),
    });

    useQuery({
        queryKey: ['ipa.filters.cattegories'],
        queryFn: getCattegories,
        enabled: IS_ENABLED_QUERY,
        staleTime: STALE_TIME,
        onSuccess: (data) => dispatch(SET_SEGMENTOS(data)),
    });

    useQuery({
        queryKey: ['ipa.lastIntegrationDate'],
        queryFn: getLastUpdate,
        staleTime: 1000 * 60 * 60, // 60 minutes,
        enabled: IS_ENABLED_QUERY,
        onSuccess: (data) => {
            dispatch(
                SET_LAST_INTEGRATION_DATE({
                    ...data,
                    parsedDate: format(
                        parseISO(data.lastExecutionDate),
                        'dd/MM/yyy, HH:mm',
                    ),
                }),
            );
        },
    });

    useQuery({
        queryKey: ['ipa/setup-form/wholesale'],
        queryFn: GET_SETUP_IPA_PRECO_ATACADO,
        staleTime: STALE_TIME,
        enabled: IS_ENABLED_QUERY,
        onSuccess: (data) => dispatch(SET_SETUP_WHOLESALE(data)),
    });

    return children;
};

export default withRouter(IPAProvider);
