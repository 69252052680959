import { format } from 'date-fns';
import { Alert } from 'rsuite';

import { lead } from '../../../../services/EventLeadService';

import iconLowQuartile from '../../../../assets/icons/icon_boxplot_low-quartile.svg';
import iconMax from '../../../../assets/icons/icon_boxplot_max.svg';
import iconMedian from '../../../../assets/icons/icon_boxplot_median.svg';
import iconMin from '../../../../assets/icons/icon_boxplot_min.svg';
import iconUpperQuartile from '../../../../assets/icons/icon_boxplot_upper-quartile.svg';
import iconChain from '../../../../assets/icons/icon_chain.svg';
import iconFrequence from '../../../../assets/icons/icon_price-2.svg';
import iconAverage from '../../../../assets/icons/icon_price.svg';
import iconStore from '../../../../assets/icons/icon_store.svg';

export const dateLocale = {
    sunday: 'Dom',
    monday: 'Seg',
    tuesday: 'Ter',
    wednesday: 'Qua',
    thursday: 'Qui',
    friday: 'Sex',
    saturday: 'Sáb',
    ok: 'OK',
    today: 'Hoje',
};

export function getModel(props, query, name, identificadores) {
    return {
        query: query || '',
        field: name || null,
        login: JSON.parse(localStorage.getItem('usuario'))?.email,
        cliente: JSON.parse(localStorage.getItem('cliente'))?.codigo,
        dataInicio: props.filtrosData?.dataInicio
            ? format(props.filtrosData.dataInicio, 'yyyy-MM-dd')
            : null,
        dataFim: props.filtrosData?.dataFim
            ? format(props.filtrosData.dataFim, 'yyyy-MM-dd')
            : null,
        rede:
            name && name === 'rede'
                ? null
                : props.rede?.value?.length
                  ? props.rede.value
                  : null,
        tipoLoja:
            name && name === 'tipoLoja'
                ? null
                : props.filtrosData?.tipoLoja?.value?.length
                  ? props.filtrosData.tipoLoja.value
                  : null,
        uf:
            name && name === 'uf'
                ? null
                : props.filtrosData?.uf?.value?.length
                  ? props.filtrosData.uf.value
                  : null,
        cidade:
            name && name === 'cidade'
                ? null
                : props.filtrosData?.cidade?.value?.length
                  ? props.filtrosData.cidade.value
                  : null,
        categoria:
            name && name === 'categoria'
                ? null
                : props.filtrosData?.categoria?.value?.length
                  ? props.filtrosData.categoria.value
                  : null,
        secao:
            name && name === 'secao'
                ? null
                : props.filtrosData?.secao?.value?.length
                  ? props.filtrosData.secao.value
                  : null,
        gtin:
            name && name === 'produto'
                ? null
                : props.produto?.cache?.length
                  ? props.produto.cache.map((item) => item.identificador)
                  : null,
        tipoPreco:
            name && name === 'tipoPreco'
                ? null
                : props.filtrosData?.tipoPreco?.value?.length
                  ? props.filtrosData.tipoPreco.value
                  : null,
        tipoProduto:
            name && name === 'tipoProduto'
                ? null
                : props.filtrosData?.tipoProduto?.value?.length
                  ? props.filtrosData.tipoProduto.value
                  : null,
        fabricante:
            name && name === 'fabricante'
                ? null
                : props.filtrosData?.fabricante?.value?.length
                  ? props.filtrosData?.fabricante?.value
                  : null,
        origemPreco:
            name && name === 'origemPreco'
                ? null
                : props.filtrosData?.origemPreco?.value?.length
                  ? props.filtrosData?.origemPreco?.value
                  : null,
        latitude: props.filtrosData?.endereco?.latitude
            ? props.filtrosData.endereco.latitude
            : null,
        longitude: props.filtrosData?.endereco?.longitude
            ? props.filtrosData.endereco.longitude
            : null,
        raio: props.filtrosData?.raio?.value
            ? props.filtrosData.raio.value
            : null,
        preco: props.filtrosData?.preco?.name
            ? props.filtrosData.preco.name
            : 'PRECO_PAGO',
        limitProducts: props.produto?.limitProducts || null,
        identificadores: identificadores || null,
        addNotFound: true,
        canal:
            props.filtrosData?.canal?.length === 2 ||
            !props.filtrosData?.canal?.length
                ? []
                : props.filtrosData?.canal,
        anuncios: props.filtrosData?.ad,
        anuncioMidia:
            name && name === 'midia'
                ? null
                : props.filtrosData?.midia?.value?.length
                  ? props.filtrosData?.midia?.value
                  : null,
        anuncioMecanica:
            name && name === 'mecanica'
                ? null
                : props.filtrosData?.mecanica?.value?.length
                  ? props.filtrosData?.mecanica?.value
                  : null,
        anuncioGrupoDeMidia:
            name && name === 'grupoMidia'
                ? null
                : props.filtrosData?.grupoMidia?.value?.length
                  ? props.filtrosData?.grupoMidia?.value
                  : null,
    };
}

export function getModelDashboards(props) {
    return {
        data_inicio: props.dataInicio
            ? format(props.dataInicio, 'yyyy-MM-dd')
            : null,
        data_fim: props.dataFim ? format(props.dataFim, 'yyyy-MM-dd') : null,
        rede: props.rede?.value?.length ? props.rede.value : null,
        tipo_loja: props?.tipoLoja?.value?.length ? props.tipoLoja.value : null,
        loja_cliente: null,
        cnpj: props?.loja?.value?.length ? props.loja.value : null,
        uf: props?.uf?.value?.length ? props.uf.value : null,
        cep: null,
        latitude: props?.endereco?.latitude ? props?.endereco?.latitude : null,
        longitude: props?.endereco?.longitude
            ? props?.endereco?.longitude
            : null,
        raio: props?.raio?.value ? props.raio.value : null,
        cidade: props?.cidade?.value?.length ? props.cidade.value : null,
        categoria: props?.categoria?.value?.length
            ? props.categoria.value
            : null,
        secao: props?.secao?.value?.length ? props.secao.value : null,
        gtin: props.produto?.cache?.length
            ? props.produto.cache.map((item) => item.identificador)
            : null,
        sku: null,
        tipo_preco: props?.tipoPreco?.value?.length
            ? props.tipoPreco.value
            : null,
        preco: props?.preco?.name ? props.preco.name : 'PRECO_PAGO',
        tipo_produto: props?.tipoProduto?.value?.length
            ? props.tipoProduto.value
            : null,
        fabricante: props?.fabricante?.value?.length
            ? props?.fabricante?.value
            : null,
        marca: props?.marca?.value?.length ? props?.marca?.value : null,
        gramatura_descricao: props?.conteudo?.value?.length
            ? props?.conteudo?.value
            : null,
        origem_preco: props?.origemPreco?.value?.length
            ? props?.origemPreco?.value
            : null,
        canal:
            props.canal?.length === 2 || !props.canal?.length
                ? []
                : props.canal,
        anuncios: props.ad,
        anuncio_midia: props?.midia?.value?.length ? props?.midia?.value : null,
        anuncio_mecanica: props?.mecanica?.value?.length
            ? props?.mecanica?.value
            : null,
        anuncio_grupo_de_midia: props?.grupoMidia?.value?.length
            ? props?.grupoMidia?.value
            : null,
    };
}

export function getModelPanel(props, query, name, identificadores) {
    return {
        query: query || '',
        field: name || null,
        login: JSON.parse(localStorage.getItem('usuario'))?.email,
        cliente: JSON.parse(localStorage.getItem('cliente'))?.codigo,
        dataInicio: props.dataInicio
            ? format(props.dataInicio, 'yyyy-MM-dd')
            : null,
        dataFim: props?.dataFim ? format(props.dataFim, 'yyyy-MM-dd') : null,
        rede:
            name && name === 'rede'
                ? null
                : props.rede?.value?.length
                  ? props.rede.value
                  : null,
        tipoLoja:
            name && name === 'tipoLoja'
                ? null
                : props?.tipoLoja?.value?.length
                  ? props.tipoLoja.value
                  : null,
        cnpj:
            name && name === 'cnpj'
                ? null
                : props?.loja?.value?.length
                  ? props.loja.value
                  : null,
        uf:
            name && name === 'uf'
                ? null
                : props?.uf?.value?.length
                  ? props.uf.value
                  : null,
        cidade:
            name && name === 'cidade'
                ? null
                : props?.cidade?.value?.length
                  ? props.cidade.value
                  : null,
        categoria:
            name && name === 'categoria'
                ? null
                : props?.categoria?.value?.length
                  ? props.categoria.value
                  : null,
        secao:
            name && name === 'secao'
                ? null
                : props?.secao?.value?.length
                  ? props.secao.value
                  : null,
        gtin:
            name && name === 'produto'
                ? null
                : props.produto?.cache?.length
                  ? props.produto.cache.map((item) => item.identificador)
                  : null,
        tipoPreco:
            name && name === 'tipoPreco'
                ? null
                : props?.tipoPreco?.value?.length
                  ? props.tipoPreco.value
                  : null,
        tipoProduto:
            name && name === 'tipoProduto'
                ? null
                : props?.tipoProduto?.value?.length
                  ? props.tipoProduto.value
                  : null,
        fabricante:
            name && name === 'fabricante'
                ? null
                : props?.fabricante?.value?.length
                  ? props?.fabricante?.value
                  : null,
        marca:
            name && name === 'marca'
                ? null
                : props?.marca?.value?.length
                  ? props?.marca?.value
                  : null,
        gramaturaDescricao:
            name && name === 'gramatura_descricao'
                ? null
                : props?.conteudo?.value?.length
                  ? props?.conteudo?.value
                  : null,
        origemPreco:
            name && name === 'origemPreco'
                ? null
                : props?.origemPreco?.value?.length
                  ? props?.origemPreco?.value
                  : null,
        latitude: props?.endereco?.latitude ? props.endereco.latitude : null,
        longitude: props?.endereco?.longitude ? props.endereco.longitude : null,
        raio: props?.raio?.value ? props.raio.value : null,
        preco: props?.preco?.name ? props.preco.name : 'PRECO_PAGO',
        limitProducts: props.produto?.limitProducts || null,
        identificadores: identificadores || null,
        addNotFound: true,
        canal:
            props.canal?.length === 2 || !props.canal?.length
                ? []
                : props.canal,
        anuncios: props?.ad,
        anuncioMidia:
            name && name === 'midia'
                ? null
                : props?.midia?.value?.length
                  ? props?.midia?.value
                  : null,
        anuncioMecanica:
            name && name === 'mecanica'
                ? null
                : props?.mecanica?.value?.length
                  ? props?.mecanica?.value
                  : null,
        anuncioGrupoDeMidia:
            name && name === 'grupoMidia'
                ? null
                : props?.grupoMidia?.value?.length
                  ? props?.grupoMidia?.value
                  : null,
    };
}

export function getModelProdutoRede(filters, product, rede) {
    const model = getModelDashboards(filters);
    if (product) {
        model.gtin = [product];
    }
    if (rede) {
        model.rede = [rede];
    }
    return model;
}

export function getModelProduct(filters, product) {
    const model = getModelDashboards(filters);
    model.gtin = [product];
    return model;
}

export function getModelRede(filters, rede) {
    const model = getModelDashboards(filters);
    model.rede = [rede];
    return model;
}

export function getMapComponentData(type, props, filteredProduct) {
    const filteredModel =
        filteredProduct && getModelProduct(props, filteredProduct);

    if (type === 'loja') {
        if (filteredProduct) {
            props.getMarkersData(filteredModel);
        } else {
            props.getMarkersData(getModelDashboards(props));
        }
    }
    if (type === 'estadual' || type === 'estado') {
        if (filteredProduct) {
            props.getMapData(filteredModel, type);
        } else {
            props.getMapData(getModelDashboards(props), type);
        }
    }
}

export function sendLead(user, identifier) {
    lead(identifier, user).then(() => {
        Alert.success('Em breve entraremos em contato!');
    });
}

export function getGroupBy(type) {
    switch (type) {
        case 'produtoRede':
            return ['GTIN', 'REDE'];
        case 'fabricanteUf':
            return ['FABRICANTE', 'UF'];
        case 'rede':
            return ['REDE'];
        case 'produto':
            return ['GTIN'];
        case 'uf':
            return ['UF'];
        case 'produtoUf':
            return ['GTIN', 'UF'];
        case 'regiao':
            return ['REGIAO'];
        case 'produtoRegiao':
            return ['GTIN', 'REGIAO'];
        case 'fabricante':
            return ['FABRICANTE'];
        case 'fabricanteRegiao':
            return ['FABRICANTE', 'REGIAO'];
        case 'semAgrupamento':
            return [];
        default:
            return ['GTIN'];
    }
}

export function getOrder(type) {
    switch (type) {
        case 'produtoRede':
            return [
                {
                    by: 'GTIN',
                },
                {
                    by: 'DATAPOINTS',
                    ascending: false,
                },
            ];
        case 'produtoUf':
            return [
                {
                    by: 'GTIN',
                },
                {
                    by: 'DATAPOINTS',
                    ascending: false,
                },
            ];
        case 'produtoRegiao':
            return [
                {
                    by: 'GTIN',
                },
                {
                    by: 'DATAPOINTS',
                    ascending: false,
                },
            ];
        default:
            return [
                {
                    by: 'DATAPOINTS',
                    ascending: false,
                },
            ];
    }
}

export function formatPrice(price) {
    let formatPrice = price.toString();
    if (formatPrice.includes('.')) {
        formatPrice = formatPrice.replace('.', ',');
    }

    return `R$ ${formatPrice}`;
}

export function getDiffDays(date) {
    const currentDate = new Date();
    const lastShownDate = new Date(date);
    const diffTime = Math.abs(currentDate - lastShownDate);
    const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
    return diffDays;
}

export const numberWithCommas = (x) =>
    x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.');

export const seriesColors = [
    '#76D680',
    '#A371E2',
    '#DFA25B',
    '#F25E5E',
    '#609BCE',
    '#B30089',
    '#FFB46E',
    '#FFD76E',
    '#5FC8FF',
    '#FF73C3',
];

export function setTooltip(props) {
    if (props) {
        const formatY = props?.chart?.hoverPoint?.y
            ? Number(props.chart.hoverPoint.y).toFixed(2).replace('.', ',')
            : null;
        return (
            `<span><b>${props.chart?.hoverPoint?.name}</b> <br>` +
            `<span style="color:${props.chart?.hoverPoint?.color}">■</span> ` +
            `<span><b>${props.chart?.hoverPoint?.datapoints} preços</b></span> ` +
            `<span> (${formatY}%)</span>`
        );
    }
    return '';
}

export function formatterXLabel(props) {
    return `<b>${props.value}</b>`;
}

export function formatterYLabel(props) {
    return `${props.value}%`;
}

export const topPricesChart = {
    chart: {
        type: 'bar',
    },
    title: {
        text: '',
    },
    legend: {
        enabled: false,
    },
    exporting: {
        enabled: false,
    },
    tooltip: {
        useHTML: true,
        shared: false,
        formatter: (props) => setTooltip(props),
    },
    plotOptions: {
        bar: {
            dataLabels: {
                enabled: true,
                format: '{y:.2f}%',
            },
        },
    },
    yAxis: {
        title: {
            text: 'FREQUÊNCIA',
        },
        breakSize: 1,
        alignTicks: false,
        labels: {
            formatter: (props) => formatterYLabel(props),
        },
    },
    xAxis: {
        title: {
            text: 'PREÇOS',
        },
        categories: [],
        labels: {
            formatter: (props) => formatterXLabel(props),
        },
    },
    series: [],
};

export const optionsGroupBy = [
    { label: 'Produto', value: 'produto' },
    { label: 'Rede', value: 'rede' },
    { label: 'Produto e rede', value: 'produtoRede' },
    { label: 'Fabricante', value: 'fabricante' },
];

export const optionsVisualize = [
    { label: 'Gráfico', value: 'chart' },
    { label: 'Tabela', value: 'table' },
];

export const renderBoxPlotChart = (
    data,
    typeVisualization,
    mapDataCategories,
    mapDataSeries,
) => ({
    chart: {
        type: 'boxplot',
        inverted: true,
        height:
            data?.content?.length <= 5
                ? 400
                : data?.content?.length >= 6 && data?.content?.length <= 8
                  ? 600
                  : 800,
    },
    title: {
        text: '',
    },
    legend: {
        enabled: false,
    },
    xAxis: {
        categories: mapDataCategories(data?.content, typeVisualization),
        title: {
            text:
                typeVisualization === 'produtoRede'
                    ? 'PRODUTOS REDE'
                    : typeVisualization === 'rede'
                      ? 'REDE'
                      : 'PRODUTO',
        },
        labels: {
            style: {},
        },
    },
    yAxis: [
        {
            title: {
                text: 'PREÇO',
            },
            labels: {
                formatter() {
                    let price = this.axis.defaultLabelFormatter.call(this);
                    if (price.indexOf(',') !== -1) {
                        price = price.replace(',', '.');
                    }
                    return `R$ ${Number(price).toFixed(2).replace('.', ',')}`;
                },
            },
        },
        {
            title: {
                text: '',
            },
            linkedTo: 0,
            labels: {
                formatter() {
                    let price = this.axis.defaultLabelFormatter.call(this);
                    if (price.includes('.')) {
                        price = price.replace('.', '');
                    }
                    if (price.indexOf(',') !== -1) {
                        price = price.replace(',', '.');
                    }
                    return `R$ ${Number(price).toFixed(2).replace('.', ',')}`;
                },
            },
            opposite: true,
        },
    ],
    tooltip: {
        useHTML: true,
        formatter() {
            return (
                `<p>${this.x}</p>` +
                `<p><img src='${iconMax}'/>Máximo: <b>${this.point.maxPrice
                    .toFixed(2)
                    .replace('.', ',')}</b></p>` +
                `<p><img src='${iconUpperQuartile}'/>Quartil superior: <b>${this.point.q3
                    .toFixed(2)
                    .replace('.', ',')}</b></p>` +
                `<p><img src='${iconMedian}'/>Mediana: <b>${this.point.median
                    .toFixed(2)
                    .replace('.', ',')}</b></p>` +
                `<p><img src='${iconLowQuartile}'/>Quartil inferior: <b>${this.point.q1
                    .toFixed(2)
                    .replace('.', ',')}</b></p>` +
                `<p><img src='${iconMin}'/>Mínimo: <b>${this.point.minPrice
                    .toFixed(2)
                    .replace('.', ',')}</b></p>` +
                `<p><img src='${iconAverage}'/>Preço médio: <b>${this.point.averagePrice
                    .toFixed(2)
                    .replace('.', ',')}</b></p>` +
                `<p><img src='${iconFrequence}'/>Preço mais frequente: <b>${this.point.frequentPrice
                    .toFixed(2)
                    .replace('.', ',')}</b></p>` +
                `<p><img src='${iconStore}'/># de lojas: <b>${this.point.numberStores}</b></p>` +
                `<p><img src='${iconChain}'/># de redes: <b>${this.point.numberRedes}</b></p>`
            );
        },
        padding: 15,
    },
    exporting: {
        enabled: false,
    },
    plotOptions: {
        series: {
            color: '#979797',
            pointWidth: 30,
            stickyTracking: true,
        },
        boxplot: {
            medianColor: '#4F4F4F',
            medianWidth: 3,
        },
    },
    series: mapDataSeries(data?.content),
});

export function getUserPlanType(plan) {
    const type = {
        Pro: 'pill-primary',
        Flex: 'pill-secondary',
        Free: 'pill-tertiary',
    };
    return type[plan];
}

export function disabledColumns(adicionais) {
    if (!adicionais?.PAN?.includes('SB')) {
        return [
            'DATA_PRECO',
            'GTIN',
            'CNPJ',
            'ANUNCIO_FLAG',
            'ANUNCIO_PRECO',
            'ANUNCIO_CAMPANHA',
            'ANUNCIO_MECANICA',
            'ANUNCIO_MECANICA_DETALHE',
            'ANUNCIO_GRUPO_DE_MIDIA',
            'ANUNCIO_MIDIA',
        ];
    }
    return ['DATA_PRECO', 'GTIN', 'CNPJ'];
}

export function isShoppingBrasilColumn(item) {
    switch (item) {
        case 'ANUNCIO_FLAG':
            return true;
        case 'ANUNCIO_PRECO':
            return true;
        case 'ANUNCIO_CAMPANHA':
            return true;
        case 'ANUNCIO_MECANICA':
            return true;
        case 'ANUNCIO_MECANICA_DETALHE':
            return true;
        case 'ANUNCIO_GRUPO_DE_MIDIA':
            return true;
        case 'ANUNCIO_MIDIA':
            return true;
        default:
            return false;
    }
}

export function filterColumnsShoppingBrasil(columns) {
    return columns.filter((item) => !isShoppingBrasilColumn(item.name));
}

export const formatProductDashboardPrice = (number) => {
    if (number === null) {
        return 0;
    }
    const isOneCharacter = number?.toString().split('.')[1]?.length;
    if (isOneCharacter === 1) {
        return `${number.toString().replace('.', ',')}0`;
    }
    return number?.toString().replace('.', ',');
};

export function formatNumberWithComma(value) {
    if (value) {
        return value.toFixed(2).replace('.', ',');
    }
    return '-';
}

export const shoppingBrasilList = [
    'ANUNCIO_FLAG',
    'ANUNCIO_PRECO',
    'ANUNCIO_CAMPANHA',
    'ANUNCIO_MECANICA',
    'ANUNCIO_MECANICA_DETALHE',
    'ANUNCIO_GRUPO_DE_MIDIA',
    'ANUNCIO_MIDIA',
];

export const setNameAttr = (name) => {
    switch (name) {
        case 'loja':
            return 'cnpj';
        case 'conteudo':
            return 'gramatura_descricao';
        default:
            return name;
    }
};
