import React, { useEffect, useReducer } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import {
    Button,
    Container,
    Notification,
} from 'rsuite';
import { trackEvent } from '../../../../../../utils/MatomoConfig';
import arrowLeftIcon2 from '../../../../../../assets/icons/icon_arrow-left2.svg';
import greenCheckIcon from '../../../../../../assets/icons/icon_check_circle_green.svg';
import { LoadingSpinerArea } from '../../../../../../components/LoadingSpinner';
import ModalConfirm from '../../../../../../components/ModalConfirm';
import { isGtinValid } from '../../../../MPDV/RevisaoLista/utils';
import PageHeader from '../../Components/PageHeader';
import { RequestAddressContainer } from '../../Components/RequestAddressContainer';
import { RequestAddressForm } from '../../Components/RequestAddressForm';
import { SendNewRequestInfo } from '../../Components/SendNewRequestInfo';
import { SendRequestButtonArea } from '../../Components/SendRequestButtonArea';
import useOnDemandSummary from '../../Hooks/useOnDemandSummary';
import useSystemRequestSelectRegion from '../../Hooks/useSystemRequestSelectRegion';
import { initialState, requestInSystemReducer } from '../../Reducer/RequestInSystemReducer';
import { changeSystemRequestStatus, formObj, sendRequestBody } from '../../data';
import { getCep, sendSystemRequest, validateGtins } from '../../services';
import { FormValues } from '../../types';

import { ProgressBar } from '../../Components/ProgressBar';
import styles from './styles.module.scss';

export const RequestInSystemContent = () => {
    const [state, dispatch] = useReducer(requestInSystemReducer, initialState);
    const history = useHistory();

    const {
        selectData, handleGetRegion, handleSelectRegion,
    } = useSystemRequestSelectRegion();

    const { summaryData, handleGetSummary } = useOnDemandSummary();

    const isFormFilled
        = state.addressComponentList?.slice(-1)[0].uf !== ''
        && state.addressComponentList?.slice(-1)[0].cidade !== ''
        && state.addressComponentList?.slice(-1)[0].raio !== ''
        && state.addressComponentList?.slice(-1)[0].codeOrText?.length !== 0;

    const sumAllGtins = () => {
        const sum = state.addressComponentList.map((item) => item.codeOrText).flat(1);
        return sum.length;
    };

    const handleAddAddressComponent = () => {
        if (state.addressComponentList?.length && isFormFilled) {
            dispatch({
                type: 'SET_ADDRESS_COMPONENT_LIST',
                addressComponentList: [
                    ...state.addressComponentList,
                    {
                        ...formObj,
                        id: state.addressComponentList?.length + 1,
                    },
                ],
            });
        }
    };

    const handleRemoveFormAddress = (id: number) => {
        const filteredAddress = state.addressComponentList?.filter((item: FormValues) => item.id !== id);
        dispatch({
            type: 'SET_ADDRESS_COMPONENT_LIST',
            addressComponentList: filteredAddress,
        });
        Notification.open({
            key: 'remove-address-success',
            className: styles['send-request-success'],
            duration: 5000,
            description: (
                <>
                    <img src={greenCheckIcon} alt="" />
                    <p>Solicitação removida com sucesso</p>
                </>
            ),
        });
    };

    const handleGetSelectAddress = (index: number, name: string) => {
        handleGetRegion(name, index, null);
    };

    const handleSendSystemRequest = async () => {
        dispatch({
            type: 'SET_SHOW_MODAL',
            showModal: false,
        });

        const body = state.addressComponentList?.map((item) => ({
            ...sendRequestBody,
            uf: item.uf,
            cidade: item.cidade,
            cep: item.cep,
            logradouro: item.endereco,
            numeroLogradouro: item.numero,
            raio: item.raio,
            codeOrText: item.codeOrText,
        }));

        try {
            const res = await sendSystemRequest(body);
            if (res.length) {
                Notification.open({
                    key: 'send-request-success',
                    className: styles['send-request-success'],
                    duration: 5000,
                    description: (
                        <>
                            <img src={greenCheckIcon} alt="" />
                            <p>Solicitação enviada com sucesso</p>
                        </>
                    ),
                });
                dispatch({
                    type: 'SET_ADDRESS_COMPONENT_LIST',
                    addressComponentList: initialState.addressComponentList,
                });
                history.push('/isa/solicitacao-ondemand');
            }
        } catch (error) {
            dispatch({
                type: 'SET_STATE',
                name: 'activeRequests',
                data: {
                    show: true,
                    message: error.response.data[0].mensagem,
                },
            });
        }
    };

    const handleGetCepRequest = async (index: number, query: string) => {
        dispatch({
            type: 'SET_CEP_LOADING',
            cepLoading: true,
        });
        dispatch({
            type: 'SET_CEP_ERROR',
            cepError: false,
        });
        const res = await getCep(query);
        if (res.cep) {
            const newArray = state.addressComponentList?.map((item) => (
                item.id === index ? ({ ...item, cep: res.cep, endereco: res.logradouro }) : item
            ));
            const hasSameCity = state.addressComponentList?.filter((item) => (item.id === index))[0].cidade;

            dispatch({
                type: 'SET_CEP_LOADING',
                cepLoading: false,
            });

            dispatch({
                type: 'SET_ADDRESS_COMPONENT_LIST',
                addressComponentList: newArray,
            });
            if (hasSameCity !== res.localidade.toUpperCase()) {
                dispatch({
                    type: 'SET_CEP_ERROR',
                    cepError: true,
                });
            }
        } else {
            dispatch({
                type: 'SET_CEP_LOADING',
                cepLoading: false,
            });
        }
    };

    const handleSelectRequestRegion = (index: number, name: string, value: string | number) => {
        const newArray = state.addressComponentList?.map((item) => (
            item.id === index ? ({ ...item, [name]: value }) : item
        ));
        handleSelectRegion(index, name, value);

        dispatch({
            type: 'SET_ADDRESS_COMPONENT_LIST',
            addressComponentList: newArray,
        });
        dispatch({
            type: 'SET_CEP_ERROR',
            cepError: false,
        });
    };

    const handleSearchCidade = (index: number, query: string) => {
        handleGetRegion('cidade', index, query);
    };

    const handleChangeTextInputRequestRegion = (index: number, name: string, value: string) => {
        const newArray = state.addressComponentList?.map((item) => (
            item.id === index ? ({ ...item, [name]: value }) : item
        ));
        dispatch({
            type: 'SET_ADDRESS_COMPONENT_LIST',
            addressComponentList: newArray,
        });

        if (name === 'cep' && value.length >= 8) {
            handleGetCepRequest(index, value);
        }
    };

    const filterInvalidGtin = async (data: string[]) => {
        const filterInvalidKeywords = data?.filter((item) => item.match(/[A-Za-z]/g)?.length <= 1);
        const hasOnlyGtinNumbers = data?.filter((item) => /^\d+$/.test(item));

        const resp = await validateGtins(hasOnlyGtinNumbers.join(','));

        if (resp.gtinsErrors) {
            dispatch({
                type: 'SET_INVALID_GTINS',
                invalidGtins: resp.gtinsErrors.concat(filterInvalidKeywords),
            });
        } else {
            dispatch({
                type: 'SET_INVALID_GTINS',
                invalidGtins: filterInvalidKeywords,
            });
        }
    };

    const handleChangeTag = (index: number, value: string[]) => {
        const newArray = state.addressComponentList?.map((item) => (
            item.id === index ? ({ ...item, codeOrText: value }) : item
        ));
        filterInvalidGtin(value);
        dispatch({
            type: 'SET_ADDRESS_COMPONENT_LIST',
            addressComponentList: newArray,
        });
        sumAllGtins();
        handleGetSummary();
    };

    const handleClearTagInput = (index: number) => {
        const newArray = state.addressComponentList?.map((item) => (
            item.id === index ? ({ ...item, codeOrText: [] }) : item
        ));
        dispatch({
            type: 'SET_INVALID_GTINS',
            invalidGtins: [],
        });
        dispatch({
            type: 'SET_ADDRESS_COMPONENT_LIST',
            addressComponentList: newArray,
        });
    };

    const handleOnClickRemoveAddress = (id: number) => {
        dispatch({
            type: 'SET_REMOVE_ADDRESS_ID',
            removeAddressId: id,
        });
        dispatch({
            type: 'SET_MODAL_TYPE',
            modalType: 'removeAddress',
        });
        dispatch({
            type: 'SET_SHOW_MODAL',
            showModal: true,
        });
    };

    const handleOnClickSendRequest = () => {
        trackEvent('On Demand', 'Enviar solicitações via tela');

        dispatch({
            type: 'SET_MODAL_TYPE',
            modalType: 'sendRequest',
        });
        dispatch({
            type: 'SET_SHOW_MODAL',
            showModal: true,
        });
    };

    const handleModalConfirm = (type: string) => {
        const handle = {
            sendRequest: () => handleSendSystemRequest(),
            removeAddress: () => handleRemoveFormAddress(state.removeAddressId),
            limitExceeded: () => dispatch({
                type: 'SET_SHOW_MODAL',
                showModal: false,
            }),
        };

        return handle[type]();
    };

    const handleRemoveInvalidGtinFromList = (id: number) => {
        const filterRequestObj = state.addressComponentList.filter((item) => item.id === id);
        const validGtin = filterRequestObj[0].codeOrText?.filter((item) => !state.invalidGtins.some((i) => i === item));
        const newArray = state.addressComponentList?.map((item) => (
            item.id === id ? ({ ...item, codeOrText: validGtin }) : item
        ));

        dispatch({
            type: 'SET_ADDRESS_COMPONENT_LIST',
            addressComponentList: newArray,
        });

        dispatch({
            type: 'SET_INVALID_GTINS',
            invalidGtins: [],
        });
    };

    const goBackToOnDemandRequest = () => {
        history.push('/isa/solicitacao-ondemand');
    };

    const handleShowActiveRequestsModal = () => {
        dispatch({
            type: 'SET_STATE',
            name: 'activeRequests',
            data: {
                show: false,
                message: '',
            },
        });
    };

    useEffect(() => {
        sumAllGtins();
        handleGetSummary();
    }, []);

    return (
        <Container className={styles['main-container']}>
            <LoadingSpinerArea size="md" area="send-request" />
            <Button className={styles['go-back-button']} onClick={goBackToOnDemandRequest}>
                <img src={arrowLeftIcon2} alt="" />
                Voltar para Lista de solicitações
            </Button>
            <PageHeader />
            <SendNewRequestInfo />
            <RequestAddressContainer
                onClickAddAddress={handleAddAddressComponent}
                disabled={isFormFilled}
                addressFormArea={(
                        state.addressComponentList?.length ? state.addressComponentList?.map((item: FormValues) => (
                            <RequestAddressForm
                                addressIndex={item?.id}
                                style={{ marginTop: '16px' }}
                                data={selectData}
                                cepData={state.addressComponentList}
                                cepLoading={state.cepLoading}
                                cepError={state.cepError}
                                invalidGtins={state.invalidGtins}
                                onClickRemoveAddress={handleOnClickRemoveAddress}
                                onOpenSelect={handleGetSelectAddress}
                                onSelect={handleSelectRequestRegion}
                                onChangeTextInput={handleChangeTextInputRequestRegion}
                                onChangeTag={handleChangeTag}
                                onClickClearProductInput={handleClearTagInput}
                                onClickRemoveInvalidGtins={handleRemoveInvalidGtinFromList}
                                onSearchCidade={handleSearchCidade}
                            />
                        )) : null
                    )}
                progressBarArea={<ProgressBar summaryData={summaryData} requestsQuantity={sumAllGtins()} />}
            />
            <SendRequestButtonArea onClickSendRequest={handleOnClickSendRequest} />
            <ModalConfirm
                show={state.showModal}
                modalWidth="480px"
                titleFontSize="16px"
                textAlign="center"
                footerAlign="center"
                cancelButtonWidth={changeSystemRequestStatus[state.modalType]?.cancelBtnWidth}
                confirmButtonWidth={changeSystemRequestStatus[state.modalType]?.confirmBtnWidth}
                icon={changeSystemRequestStatus[state.modalType]?.icon}
                title={changeSystemRequestStatus[state.modalType]?.title}
                message={changeSystemRequestStatus[state.modalType]?.message}
                confirmButton={changeSystemRequestStatus[state.modalType]?.confirmButton}
                cancelButton={changeSystemRequestStatus[state.modalType]?.cancelButton}
                closeButton={() => dispatch({
                        type: 'SET_SHOW_MODAL',
                        showModal: false,
                    })}
                onCancel={() => dispatch({
                        type: 'SET_SHOW_MODAL',
                        showModal: false,
                    })}
                onConfirm={() => handleModalConfirm(changeSystemRequestStatus[state.modalType]?.onConfirm)}
            />
            <ModalConfirm
                show={state.activeRequests?.show}
                modalWidth="480px"
                titleFontSize="16px"
                textAlign="center"
                footerAlign="center"
                icon={changeSystemRequestStatus.limitExceeded.icon}
                title="Solicitações ativas"
                message={state.activeRequests?.message}
                confirmButton="Fechar"
                closeButton={handleShowActiveRequestsModal}
                onConfirm={handleShowActiveRequestsModal}
            />
        </Container>
    );
};
